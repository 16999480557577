import React from "react";
import {Heading,Box} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import {motion} from 'framer-motion'
import MeinSeo from '../components/SEO'
export default function Sponsoren() {
    return(
        <motion.div
        initial={{
            opacity:0
        }}
        animate={{opacity:1 }}
        transition={{duration:1}}
        >
           <MeinSeo title="Sponsoren des Theaterfestivals 2022" description="Sponsoren des Theaterfestivals der Poesie im Oberen Mittelrheintal" />
        <Box py="10">
        <Heading pl="10" fontSize={["lg","xl"]} letterSpacing=".09rem" p="3" >Ein ganz besonderer Dank gilt den Sponsoren des Theaterfestivals:</Heading>
            
            <StaticImage src="../images/theater/sponsoren.jpg" alt="Sponsoren" />
        </Box>
        
        </motion.div>
    )
}